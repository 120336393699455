import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { useAuth } from '../../../hooks/auth';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import api from '../../../services/api';

import { FiHome, FiSettings, FiUser } from 'react-icons/fi';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from "joi";
import toast from 'react-hot-toast';
import { format, isPast, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { CreditCard, UserSwitch, X } from 'phosphor-react';


import Button from '../../../components/Button';
import Input from '../../../components/Form/Input';
import MessageErrorValidator from '../../../components/MessageErrorValidator';
import { Center } from '../../../layout/components/Content/styles';
import InputMask from '../../../components/Form/InputMask';
import AvatarProgress from '../../../components/AvatarProgress';
import InputNotRegister from '../../../components/Form/InputNotRegister';
import TooltipAdapter from '../../../components/TooltipAdapter';
import Breadcrumbs from '../../../components/Breadcrumbs';
import NotFoundRegister from '../../../components/NotFoundRegister';
import DropdownButton from '../../../components/DropdownButton';
import ModalConfirm from '../../../components/ModalConfirmation';
import SimpleMultiSelect from '../../../components/Form/SimpleMultiSelect';

import { maxWidthContent } from '../../../utils/maxWidthContent';

import { Container, FooterButtons, FormProfile, HeaderSection, HeaderSectionBread, Line0Modal, Line1, Line1Modal, Line2, LineButton, LineInp, LoginLogsContainer, MainContent, ModalContainer, PaginationContainer, StatusBadge, TransactionsContainer } from './styles';

import { IUserProps } from '../../../interfaces/User';
import { ITransactionsProps } from '../../../interfaces/Transactions';
import { IBanksProps } from '../../../interfaces/Bank';
import { IGenericOptions } from '../../../interfaces/IGenericOptions';
import { ILoginLogsProps } from '../../../interfaces/LoginLogs';
import IDataTable from '../../../interfaces/DataTable';
import Pagination from '../../../components/Pagination';
import LoaderSpinner from '../../../components/LoaderSpinner';

// Validation
const schema = Joi.object({
  nickname: Joi.string().min(2).allow('').messages({ '*': 'Informe um apelido válido.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
  first_name: Joi.string().min(3).required().messages({ '*': 'Informe um nome válido.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
  last_name: Joi.string().min(3).required().messages({ '*': 'Informe um sobrenome válido.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
  cpf: Joi.string().min(11).required().messages({ '*': 'Informe um CPF válido.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
  email: Joi.string().email({ tlds: { allow: false } }).required().messages({ '*': 'Informe um e-mail válido.', 'string.email': 'Informe um e-mail válido.' }),
  birthday: Joi.string().required().messages({ '*': 'Informe uma data de nascimento válida.' }),
  phone_number: Joi.string().required().messages({ '*': 'Informe um telefone válido.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
});

const isBankingBillet = {
  is: Joi.alternatives(['banking_billet']),
  then: Joi.required(),
}

// Validation refuse transaction
const schemaRefuse = Joi.object({
  payment_method: Joi.string().required().messages({ '*': 'Informe método de pagamento válido.' }),
  account: Joi.string().min(3).when('payment_method', isBankingBillet).messages({ '*': 'Informe uma cota válida.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
  account_digit: Joi.string().when('payment_method', isBankingBillet).messages({ '*': 'Informe um dígito válido.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
  agency: Joi.string().min(2).when('payment_method', isBankingBillet).messages({ '*': 'Informe uma agência válida.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
  agency_digit: Joi.string().messages({ '*': 'Informe um dígito válido.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
  bank_code: Joi.string().when('payment_method', isBankingBillet).messages({ '*': 'Informe um banco válido.' }),
  customer_cpf: Joi.string().min(11).when('payment_method', isBankingBillet).messages({ '*': 'Informe um CPF válido.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
  customer_name: Joi.string().min(3).when('payment_method', isBankingBillet).messages({ '*': 'Informe um nome válido.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
});

const Aluno: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result: ', await joiResolver(schema)(data, context, options));
      return joiResolver(schema)(data, context, options);
    },
  });

  const { register: register2, unregister: unregister2, handleSubmit: handleSubmit2, setValue: setValue2, formState: { errors: errors2 }, reset: reset2 } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result 2: ', await joiResolver(schemaRefuse)(data, context, options));
      return joiResolver(schemaRefuse)(data, context, options);
    },
  });

  // States
  const [currentNickname, setCurrentNickname] = useState('');

  // Loaders
  const [loadingUpdateProfile, setLoadingUpdateProfile] = useState(false);
  const [loading, setLoading] = useState(false);

  // Datas
  const [currentUser, setCurrentUser] = useState<IUserProps>({} as IUserProps);
  const [userTransactionsData, setUserTransactionsData] = useState<ITransactionsProps[]>([]);
  const [banksDataOptions, setBanksDataOptions] = useState<IGenericOptions[]>([]);
  const [userLoginLogs, setUserLoginLogs] = useState<IDataTable<ILoginLogsProps>>({} as IDataTable<ILoginLogsProps>);

  // pagination user logs
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  // States
  const [currentTransaction, setCurrentTransaction] = useState<ITransactionsProps>({} as ITransactionsProps);

  // Modals
  const [modalRefondedIsOpen, setModalRefondedIsOpen] = useState(false);

  const paymentMethodVariations = {
    banking_billet: 'Boleto',
    credit_card: 'Cartão de crédito',
    pix: 'Pix',
  }

  useLayoutEffect(() => {
    const mainContentSection: any = document.querySelector('#main-scroll');
    const mainContentCenter: any = document.querySelector('#content-center');

    if (mainContentSection && mainContentCenter) {
      mainContentSection.style.padding = '0px';
      mainContentCenter.style.maxWidth = '100%';
    }

    return () => {
      if (mainContentSection && mainContentCenter) {
        mainContentSection.style.padding = '2.35rem 1rem 2rem 2rem';
        mainContentCenter.style.maxWidth = maxWidthContent;
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const [currentUser, userTransactionsResponse, userLoginLogsResponse] = await Promise.all([
          api.get(`/users/${id}`),
          api.get(`/users/${id}/purchases`, { params: { pageIndex: 0, pageSize: 999, } }),
          api.get(`/users/${id}/login-logs`, {
            params: { pageIndex: currentPage - 1, pageSize: 4, },
          }),
        ]);

        setCurrentUser(currentUser.data);

        setCurrentNickname(currentUser.data?.nickname);
        setUserTransactionsData(userTransactionsResponse.data?.records);

        setUserLoginLogs(userLoginLogsResponse.data);

        setShowingTo(userLoginLogsResponse.data?.showingTo);
        setShowingFrom(userLoginLogsResponse.data?.showingFrom);
        setMaxPages(userLoginLogsResponse.data?.maxPages);
        setTotalRecords(userLoginLogsResponse.data?.totalRecords);

        reset({
          nickname: currentUser.data.nickname || '',
          first_name: currentUser.data.first_name,
          last_name: currentUser.data.last_name,
          cpf: currentUser.data.cpf,
          email: currentUser.data.email,
          birthday: currentUser.data.birthday ? format(parseISO(currentUser.data.birthday), 'dd/MM/yyyy', { locale: ptBR }) : '',
          phone_number: currentUser.data.phone_number,
        });
      } catch (error) {
        console.log(error);
        setLoading(false);

        toast.error('Erro ao carregar dados do usuário', {
          position: 'bottom-right',
          duration: 6000,
          style: {
            background: '#F56565',
            color: '#FFF',
          }
        });
      }
      finally {
        setLoading(false);
      }
    })();
  }, [id, reset, currentPage]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoadingUpdateProfile(true);

      const dateString = data.birthday;
      const d = dateString.split('/')[0];
      const m = dateString.split('/')[1];
      const y = dateString.split('/')[2];

      const response = await api.put(`/users/${id}`, {
        first_name: data.first_name,
        last_name: data.last_name,
        nickname: data.nickname || null,
        cpf: data.cpf,
        email: data.email,
        birthday: `${y}-${m}-${d}`,
        phone_number: data.phone_number.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '').trim(),
        free_access: currentUser.free_access,
      });

      if (response.status === 200) {
        toast.success('Perfil atualizado com suecesso!', {
          position: 'bottom-right',
          duration: 6000,
          className: 'toast-samuquinha',
        });
      }

      console.log(response.data);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao atualizar perfil!', {
        position: 'bottom-right',
        duration: 6000,
        className: 'toast-samuquinha',
      });
      setLoadingUpdateProfile(false);
    } finally {
      setLoadingUpdateProfile(false);
    }
  });

  const onSubmitRefuse = handleSubmit2(async (data) => {
    try {
      console.log('data2', data);

      const response = await api.delete(`/purchases/${currentTransaction.id}`, {
        data: {
          payment_method: data.payment_method,
          account: data.account,
          account_digit: data.account_digit,
          agency: data.agency,
          agency_digit: data.agency_digit,
          bank_code: data.bank_code,
          customer_cpf: data.customer_cpf,
          customer_name: data.customer_name,
        }
      });

      console.log(response.data);

      if (response.status === 200) {
        toast.success('Estorno submetido com suecesso!', {
          position: 'bottom-right',
          duration: 6000,
          className: 'toast-samuquinha',
        });

        setModalRefondedIsOpen(false);

        setUserTransactionsData((oldTransaction) => {
          const newTransactions = oldTransaction?.map((transaction) => {
            if (transaction.id === currentTransaction.id) {
              return {
                ...transaction,
                status: response.data.status,
                status_info: response.data.status_info,
              }
            }

            return transaction;
          });

          return newTransactions;
        });
      }

    } catch (error) {
      console.log(error);
      toast.error('Erro ao submeter estorno!', {
        position: 'bottom-right',
        duration: 6000,
        className: 'toast-samuquinha',
      });
    }
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`https://brasilapi.com.br/api/banks/v1`);
        console.log(response.data);

        const removedNullBanks = response.data.filter((bank: IBanksProps) => bank.code !== null);

        const banksOptions = removedNullBanks.map((bank: IBanksProps) => {

          return {
            value: String(bank.code).padStart(3, '0'),
            label: bank.code + ' - ' + bank.name,
          }
        });

        setBanksDataOptions(banksOptions);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleRefondeTransaction = useCallback(async (transaction: ITransactionsProps) => {
    console.log('transaction:', transaction);
  }, []);

  return (
    <>
      <Container>
        <Center>
          <MainContent>
            <HeaderSection style={{ backgroundImage: `url(${currentUser.cover_url})` }}>
              <main>
                <AvatarProgress
                  badgeValue={currentUser.level}
                  percent={currentUser.current_exp}
                  size={128}
                  url={currentUser.avatar_url}
                />
                <div>
                  <h2>{currentUser.show_name}</h2>
                  <h6>{currentUser.career?.name}</h6>
                  <h6>{currentUser.university?.name}</h6>
                </div>
              </main>
            </HeaderSection>

            <HeaderSectionBread>
              <Breadcrumbs
                icon={FiHome}
                separator="/"
                route={[
                  { title: 'Alunos', link: '/alunos' },
                  { title: `${currentUser.show_name}`, link: `/aluno/${currentUser.id}` },
                ]}
              />
            </HeaderSectionBread>

            <main>
              <section>
                <FormProfile>
                  <header>
                    <h1><FiUser size={20} strokeWidth={2.5} /> Informações pessoais</h1>
                  </header>
                  <form onSubmit={onSubmit}>
                    <Line1>
                      <div>
                        <LineInp>
                          <InputNotRegister name="nickname" type="text" label="Apelido" defaultValue={currentNickname} autoComplete="off" disabled />
                          {currentNickname && (
                            <TooltipAdapter alt="Remover apelido?" place="top">
                              <Button color="error" onClick={() => {
                                if (window.confirm('Tem certeza que deseja remover seu apelido?')) {
                                  setValue('nickname', '');
                                  setCurrentNickname('');
                                }
                              }}><X weight="bold" /></Button>
                            </TooltipAdapter>
                          )}
                        </LineInp>
                      </div>
                      <div>
                        <Input register={register} name="first_name" type="text" label="Nome" autoComplete="off" defaultValue={currentUser.first_name} />
                        {errors?.first_name && <MessageErrorValidator>{errors?.first_name?.message as string}</MessageErrorValidator>}
                      </div>
                      <div>
                        <Input register={register} name="last_name" type="text" label="Sobrenome" autoComplete="off" defaultValue={currentUser.last_name} />
                        {errors?.last_name && <MessageErrorValidator>{errors?.last_name?.message as string}</MessageErrorValidator>}
                      </div>
                    </Line1>
                    <Line2>
                      <div>
                        {currentUser.id && (
                          <InputMask
                            register={register}
                            name="birthday"
                            type="text"
                            label="Data de nascimento"
                            autoComplete="off"
                            pattern="##/##/####"
                            setResult={(value) => { setValue('birthday', value) }}
                            defaultValue={currentUser?.birthday ? format(parseISO(currentUser?.birthday), "dd/MM/yyyy", { locale: ptBR }) : ''}
                          />
                        )}
                        {errors.birthday && <MessageErrorValidator>{errors.birthday.message as string}</MessageErrorValidator>}
                      </div>
                      <div>
                        <Input
                          register={register}
                          name="cpf"
                          type="text"
                          label="CPF"
                          autoComplete="off"
                          defaultValue={currentUser.cpf}
                        />
                        {errors?.cpf && <MessageErrorValidator>{errors?.cpf?.message as string}</MessageErrorValidator>}
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <Input
                          register={register}
                          name="email"
                          type="text"
                          label="E-mail"
                          autoComplete="off"
                          defaultValue={currentUser.email}
                          style={{ gap: '1rem' }}
                        />
                        {errors?.email && <MessageErrorValidator>{errors?.email?.message as string}</MessageErrorValidator>}

                      </div>

                      <div>
                        <InputMask
                          register={register}
                          name="phone_number"
                          type="text"
                          label="Telefone"
                          autoComplete="off"
                          defaultValue={currentUser.phone_number}
                          pattern="(##) #####-####"
                          setResult={(value) => { setValue('phone_number', value) }}
                          style={{ gap: '1rem' }}
                        />
                        {errors?.phone_number && <MessageErrorValidator>{errors?.phone_number?.message as string}</MessageErrorValidator>}
                      </div>

                    </Line2>
                    <LineButton>
                      <Button type="submit" color="primary" loading={loadingUpdateProfile} disabled={loadingUpdateProfile}>Atualizar perfil</Button>
                    </LineButton>
                  </form>
                </FormProfile>
              </section>
            </main>
            <TransactionsContainer>
              <header>
                <h1><CreditCard size={20} weight="duotone" /> Transações</h1>
              </header>
              {userTransactionsData.length > 0 ? userTransactionsData.map((transaction: ITransactionsProps) => (
                <div key={transaction.id}>
                  <header>
                    <h4>Id ({transaction.payment_engine})</h4>
                    <h4>Plano</h4>
                    <h4>Forma de pagamento</h4>
                    <h4>Data</h4>
                    <h4>Validade</h4>
                    <h4>Garantia</h4>
                    <h4>Cupom</h4>
                    <h4>Status</h4>
                    <h4><FiSettings size={16} strokeWidth={2.5} /></h4>
                  </header>
                  <main>
                    <div>{transaction.external_id}</div>
                    <div>
                      {transaction?.product?.title} <br />
                      valor total: {transaction.amount_formatted} <br />
                      {transaction.payment_method === 'credit_card' && `${transaction.installments} x de ${transaction.installment_amount_formatted}`}
                    </div>
                    <div>
                      {paymentMethodVariations[transaction?.payment_method]}
                    </div>
                    <div>{transaction?.start_date ? format(parseISO(transaction?.start_date), "dd'/'MM'/'Y", { locale: ptBR }) : '-'}</div>
                    <div>{transaction?.end_date ? format(parseISO(transaction?.end_date), "dd'/'MM'/'Y", { locale: ptBR }) : '-'}</div>
                    <div>
                      {transaction?.warranty_date ? (
                        <>
                          {format(parseISO(transaction?.warranty_date), "dd'/'MM'/'Y", { locale: ptBR })} <br />
                          ({isPast(parseISO(transaction?.warranty_date)) ? 'garantia expirada' : 'em garantia'})
                        </>
                      ) : (
                        '-'
                      )}
                    </div>
                    <div>{transaction?.coupon ? transaction?.coupon?.code : '-'}</div>
                    <div>
                      {transaction.status === 'refunded' ? (
                        <TooltipAdapter alt={
                          transaction?.refunder && transaction.refunded_at ?
                            `Estornado por: ${transaction?.refunder?.show_name} em ${format(parseISO(transaction?.refunded_at), "dd'/'MM'/'Y", { locale: ptBR })}`
                            :
                            `Estornado via ${transaction.payment_engine}`}
                          place="top"
                        >
                          <StatusBadge style={{ background: transaction?.status_info?.background, color: transaction?.status_info?.color }}>
                            {transaction?.status_info?.name}
                          </StatusBadge>
                        </TooltipAdapter>
                      ) : (
                        <StatusBadge style={{ background: transaction?.status_info?.background, color: transaction?.status_info?.color }}>
                          {transaction?.status_info?.name}
                        </StatusBadge>
                      )}
                    </div>
                    <div>
                      <DropdownButton>
                        <ul>
                          {(transaction.status === 'paid' && !isPast(parseISO(transaction.warranty_date))) ? (
                            <li>
                              <button type="button" onClick={() => {
                                setModalRefondedIsOpen(true);
                                setCurrentTransaction(transaction);
                                reset2({
                                  payment_method: transaction.payment_method,
                                });
                              }}>
                                <MdKeyboardArrowRight size={18} />
                                Estornar compra
                              </button>
                            </li>
                          )
                            : (
                              <li style={{ cursor: 'default' }} >
                                <button type="button" disabled style={{ cursor: 'default' }}>
                                  <MdKeyboardArrowRight size={18} />
                                  Não há ações disponíveis</button>
                              </li>
                            )
                          }
                        </ul>
                      </DropdownButton>
                    </div>
                  </main>
                </div>
              ))
                :
                (
                  <NotFoundRegister description="Nenhuma transação encontrada." emoji="💸" />
                )}
            </TransactionsContainer>
            <LoginLogsContainer style={loading ? { height: '400px', overflow: 'hidden' } : {}}>
              {loading && <LoaderSpinner blur backgroundTransparent={false} />}
              <header>
                <h1><UserSwitch size={20} weight="duotone" /> Histórico de acessos</h1>
              </header>
              {userLoginLogs?.records?.length > 0 ? userLoginLogs?.records?.map((log) => (
                <div key={log.id}>
                  <header>
                    <h4>Data</h4>
                    <h4>IP</h4>
                    <h4>Navegador</h4>
                    <h4>Dispositivo</h4>
                    <h4>S.O</h4>
                    <h4>Localização</h4>
                  </header>
                  <main>
                    <div>{new Date(log.created_at).toLocaleDateString('pt-br')}</div>
                    <div>{log.ip}</div>
                    <div>{log?.browser?.name} - {log?.browser?.version}</div>
                    <div>{log?.device?.model} - {log?.device?.type}</div>
                    <div>{log?.os?.name} - {log?.os?.version}</div>
                    <div>{log?.city}, {log?.state} - {log?.country}</div>
                  </main>
                </div>
              ))
                :
                (
                  <NotFoundRegister description="Nenhum registro encontrado." emoji="🔒" />
                )}

              {userLoginLogs && userLoginLogs?.records?.length > 0 && (
                <PaginationContainer>
                  <p>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} registros`}</p>
                  {totalRecords !== 0 && (
                    <Pagination
                      count={maxPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      noScrollOnChangePage
                    />
                  )}
                </PaginationContainer>
              )}
            </LoginLogsContainer>
          </MainContent>
        </Center>
      </Container>

      {/* START MODAL CONFIRM */}
      <ModalConfirm
        title="Confirmar estorno da compra?"
        confirmText="Confirmar"
        cancelText="Cancelar"
        text="Tem certeza que deseja proceguir com o cancelamento da compra?"
        onConfirm={() => handleRefondeTransaction(currentTransaction)}
        isOpen={modalRefondedIsOpen}
        showCancelButton={false}
        hideConfirmButton={true}
        isLoading={false}
        setIsOpen={() => setModalRefondedIsOpen((oldValue) => !oldValue)}
        closeModal={() => setModalRefondedIsOpen(false)}
      >
        <ModalContainer>
          <p style={{ fontSize: '14px' }}><strong> {currentTransaction.payment_method === 'banking_billet' ? 'Dados bancários' : 'Esta ação será Irreversível'}</strong> 💸</p>

          <form onSubmit={onSubmitRefuse}>
            {currentTransaction.payment_method === 'banking_billet' && (
              <main>
                <Line0Modal>
                  <div>
                    <SimpleMultiSelect
                      register={register2}
                      name={`bank_code`}
                      label="Banco"
                      placeHolder="Selecione..."
                      options={banksDataOptions}
                      setResult={(values) => { setValue2(`bank_code`, values) }}
                      isMulti={false}
                      required
                    />
                    {errors2?.bank_code && <MessageErrorValidator>{errors2?.bank_code?.message as string}</MessageErrorValidator>}
                  </div>
                </Line0Modal>
                <Line1Modal>
                  <div>
                    <Input
                      register={register2}
                      name="agency"
                      type="text"
                      label="Agência"
                      autoComplete="off"
                    />
                    {errors2?.agency && <MessageErrorValidator>{errors2?.agency?.message as string}</MessageErrorValidator>}
                  </div>
                  <div>
                    <Input
                      register={register2}
                      name="agency_digit"
                      type="text"
                      label="Dígito da agência"
                      autoComplete="off"
                    />
                    {errors2?.agency_digit && <MessageErrorValidator>{errors2?.agency_digit?.message as string}</MessageErrorValidator>}
                  </div>
                </Line1Modal>
                <Line1Modal>
                  <div>
                    <Input
                      register={register2}
                      name="account"
                      type="text"
                      label="Número da conta"
                      autoComplete="off"
                    />
                    {errors2?.account && <MessageErrorValidator>{errors2?.account?.message as string}</MessageErrorValidator>}
                  </div>
                  <div>
                    <Input
                      register={register2}
                      name="account_digit"
                      type="text"
                      label="Dígito da conta"
                      autoComplete="off"
                    />
                    {errors2?.account_digit && <MessageErrorValidator>{errors2?.account_digit?.message as string}</MessageErrorValidator>}
                  </div>
                </Line1Modal>
                <Line1Modal>
                  <div>
                    <Input
                      register={register2}
                      name="customer_name"
                      type="text"
                      label="Nome do titular"
                      autoComplete="off"
                      defaultValue={currentUser.full_name}
                    />
                    {errors2?.account && <MessageErrorValidator>{errors2?.account?.message as string}</MessageErrorValidator>}
                  </div>
                  <div>
                    <Input
                      register={register2}
                      name="customer_cpf"
                      type="text"
                      label="CPF do titular"
                      autoComplete="off"
                      defaultValue={currentUser.cpf}
                    />
                    {errors2?.account_digit && <MessageErrorValidator>{errors2?.account_digit?.message as string}</MessageErrorValidator>}
                  </div>
                </Line1Modal>
              </main>
            )}
            <FooterButtons>
              <Button
                color="primary"
                type="submit"
              >
                Confirmar estorno
              </Button>
            </FooterButtons>
          </form>
        </ModalContainer>
      </ModalConfirm>
      {/* END MODAL CONFIRM */}
    </>
  );
}

export default Aluno;