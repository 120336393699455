import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useAuth } from '../../../hooks/auth';
import { useTheme } from '../../../hooks/theme';

import api from '../../../services/api';

import autoAnimate from '@formkit/auto-animate';
import toast from 'react-hot-toast';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  CaretLeft,
  Eye,
  House,
  MagnifyingGlass,
  MonitorPlay,
  Timer,
  Trash,
} from 'phosphor-react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Breadcrumbs from '../../../components/Breadcrumbs';
import Loader from '../../../components/Loader';
import InputDebounceAdapter from '../../../components/InputDebounceAdapter';
import TooltipAdapter from '../../../components/TooltipAdapter';
import Hr from '../../../components/Hr';
import CardMaterial from '../../../components/CardMaterial';
import AvatarProgress from '../../../components/AvatarProgress';
import ToggleLikeAndDeslike, {
  IVoteType,
} from '../../../components/ToggleLikeAndDeslike';
import ExerciseModule from '../../../components/ExerciseModule';
import LoaderSpinner from '../../../components/LoaderSpinner';
import VideoTumbnail from '../../../components/VideoTumbnail';
import ViewedLessonButton from './ViewedLessonButton';

import {
  FullWidthContainer,
  FullWidthAside,
  FullWidthMain,
  FullWidthMainContent,
  PlayerContainer,
  ItemList,
  ModuleITitle,
  RatingVideoContainer,
  CommentsContainer,
  Center,
  Commentmain,
  MyCommentsContainer,
  TrashIcon,
  MaterialsContainer,
  BreadcrumbsContainer,
  ExerciseButtonContainer,
  SearchNotFound,
} from './styles';

import { maxWidthContent } from '../../../utils/maxWidthContent';
import { loadConfettiAllPage } from '../../../utils/useConfetti';

import { IClusterProps } from '../../../interfaces/Cluster';
import { ILessonProps } from '../../../interfaces/Lesson';
import PlayerAdapter from '../../../components/PlayerAdapter';
import { LiteVideo } from '../PlanoDeEstudosAula/styles';

export interface ICommentsProps {
  id: string;
  lesson_id: string;
  user_id: string;
  video_time?: null;
  content: string;
  created_at: string;
  updated_at: string;
}

const AulasModulo: React.FC = () => {
  const { user } = useAuth();
  const { slug, lessonSlug } = useParams<{
    slug: string;
    lessonSlug: string;
  }>();
  const { theme } = useTheme();
  const history = useHistory();

  // Refs
  const parentCommentsList = useRef<HTMLDivElement>(null);
  const parentListLessons = useRef<HTMLUListElement>(null);

  // Loading
  const [loading, setLoading] = useState(true);

  const [isExercise, setIsExercise] = useState(false);

  // Data
  const [clusterData, setClusterData] = useState<IClusterProps>(
    {} as IClusterProps,
  );
  const [lessonsData, setLessonsData] = useState<ILessonProps[]>([]);
  const [filteredLessons, setFilteredLessons] = useState<ILessonProps[]>([]);
  const [commentsData, setCommentsData] = useState<ICommentsProps[]>([]);
  const [currentView, setCurrentView] = useState(0);

  // States
  const [comment, setComment] = useState('');
  const [currentLesson, setCurrentLesson] = useState<ILessonProps | null>(null);

  // Player
  const [isPlaying, setIsPlaying] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(true);
  const [timeLeft, setTimeLeft] = useState(30);

  const clusterType = {
    main: {
      title: 'Módulos',
      link: '/modulos',
    },
    exam: {
      title: 'Vestibulares Específicos',
      link: '/vestibulares',
    },
    extra: {
      title: 'Cursos Extras',
      link: '/cursos-extras',
    }
  }

  const handleLessonCompleted = useCallback(
    async (id: string) => {
      try {
        const findLesson = lessonsData.find(
          (lesson: ILessonProps) => lesson.id === id,
        );

        if (findLesson) {
          const response = await api.post(`/clusters/lessons/${id}/progress`, {
            video_time:
              Number(findLesson.viewed) >= Number(findLesson.duration)
                ? 0
                : Number(Math.ceil(findLesson.duration)), // arredondar para cima
            is_study_plan: false,
          });

          setLessonsData(oldLessons =>
            oldLessons.map(oldLesson => {
              if (oldLesson.id === id) {
                return {
                  ...oldLesson,
                  viewed: Number(response.data.video_time),
                };
              }

              return oldLesson;
            }),
          );

          // check if all lessons is completed
          const lessonsCompleted = lessonsData.filter(
            (lesson: ILessonProps) =>
              Number(lesson.viewed) >= Number(lesson.duration),
          );
          if (
            Number(response.data.video_time) === Number(findLesson.duration) &&
            lessonsCompleted.length === lessonsData.length - 1
          ) {
            loadConfettiAllPage();

            toast.success(
              'Parabéns! Você concluiu todas as aulas desse módulo!',
              {
                position: 'bottom-right',
                duration: 6000,
                className: 'toast-samuquinha',
              },
            );
          }

          console.log(response.data);
        }
      } catch (error) {
        console.log(error);
        toast.error('Ops! Algo deu errado.', {
          position: 'bottom-center',
          duration: 6000,
        });
      }
    },
    [lessonsData],
  );

  useEffect(() => {
    const TIME_LEFT = 20;

    const interval = setInterval(() => {
      if (!isPlaying) {
        return;
      }

      setTimeLeft(oldTimeLeft => {
        if (oldTimeLeft === 0) {
          return TIME_LEFT; // reset time count 20 seconds
        }

        return oldTimeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isPlaying]);

  const handleSaveCurrentPostion = useCallback(
    async (position: number) => {
      try {
        if (!currentLesson) {
          return;
        }

        const response = await api.post(
          `/clusters/lessons/${currentLesson.id}/progress`,
          {
            video_time: Math.round(position),
            is_study_plan: false,
          },
        );

        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    [currentLesson],
  );

  const onPlay = useCallback(() => {
    console.log('onPlay');
    setIsPlaying(true);
    setShowThumbnail(false);
  }, []);

  const onPause = useCallback(() => {
    console.log('onPause');

    setIsPlaying(false);
  }, []);

  const onEnded = useCallback(
    async (lesson_id: string) => {
      console.log('onCompleted');
      setIsPlaying(false);

      handleLessonCompleted(lesson_id);
    },
    [handleLessonCompleted],
  );

  const onReady = useCallback((playerRef: any) => {
    console.log('onReady');

    if (playerRef.current) {
      if (!currentLesson) {
        return;
      }

      if (currentLesson.viewed !== 0) {
        playerRef.current.seekTo(currentLesson.viewed);
        return;
      }
    }
  }, [currentLesson]);

  const onProgress = useCallback((
    played: number,
    playedSeconds: number,
    loaded: number,
    loadedSeconds: number,
  ) => {
    console.log('onProgress');
    console.log({ played, playedSeconds, loaded, loadedSeconds });

    if (timeLeft === 0) {
      handleSaveCurrentPostion(playedSeconds); // send to backend
    }
  },
    [handleSaveCurrentPostion, timeLeft],
  );

  // END Player

  useLayoutEffect(() => {
    const mainContentSection: any = document.querySelector('#main-scroll');
    const mainContentCenter: any = document.querySelector('#content-center');
    const mainFooter: any = document.querySelector('#main-footer-container');

    if (mainContentSection && mainContentCenter && mainFooter) {
      mainContentSection.style.padding = '0px';
      mainContentCenter.style.maxWidth = '100%';
      mainFooter.style.display = 'none';
    }

    return () => {
      if (mainContentSection && mainContentCenter) {
        mainContentSection.style.padding = '2.35rem 1rem 2rem 2rem';
        mainContentCenter.style.maxWidth = maxWidthContent;
        mainFooter.style.display = 'block';
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/clusters/${slug}`);

        console.log(response.data);

        const { lessons, ...cluster } = response.data;
        setClusterData(cluster);
        setLessonsData(lessons);
        setFilteredLessons(lessons);
      } catch (error) {
        console.log(error);
        setLoading(false);

        toast.error('Desculpe, este módulo não foi encontrado! 😥', {
          duration: 5000,
          position: 'bottom-right',
          className: 'toast-samuquinha',
        });

        history.push('/modulos');

      } finally {
        setLoading(false);
      }
    })();
  }, [slug, history]);

  useEffect(() => {
    setFilteredLessons(oldLesson =>
      oldLesson.map(lesson => {
        const findLesson = lessonsData.find(
          lessonData => lessonData.id === lesson.id,
        );

        if (findLesson) {
          return findLesson;
        }

        return lesson;
      }),
    );
  }, [lessonsData]);

  useEffect(() => {
    (async () => {
      try {
        if (currentLesson) {
          const [response1, response2] = await Promise.all([
            api.get(`/clusters/${currentLesson.cluster_id}/lessons/${currentLesson.slug}/notes`),
            api.patch(`/clusters/lessons/${currentLesson.id}/views`),
          ]);

          setCommentsData(response1.data);
          setCurrentView(response2.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentLesson]);

  useEffect(() => {
    parentCommentsList.current && autoAnimate(parentCommentsList.current);
    parentListLessons.current && autoAnimate(parentListLessons.current);
  }, [parentCommentsList, parentListLessons]);

  const handleFilterSchedule = useCallback(
    (inputValue: string) => {
      // implement search lesson filter by title on keyup event search input

      if (inputValue) {
        const filteredLessons = lessonsData.filter(lesson => {
          return lesson.title.toLowerCase().includes(inputValue.toLowerCase());
        });

        setFilteredLessons(filteredLessons);
      }

      if (!inputValue) {
        setFilteredLessons(lessonsData);
      }
    },
    [lessonsData],
  );

  const handleChangeType = useCallback((value: boolean) => {
    setIsExercise(value);
    const mainScroll = document.querySelector('#main-scroll');

    if (mainScroll) {
      mainScroll.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  const handlePostComment = useCallback(async () => {
    try {
      if (!currentLesson) {
        return;
      }

      const response = await api.post(
        `/clusters/${currentLesson.cluster_id}/lessons/${currentLesson.slug}/notes`,
        {
          video_time: null,
          content: comment,
        },
      );
      console.log(response.data);
      toast.success('Anotação criada com sucesso!', {
        position: 'bottom-right',
        duration: 6000,
        className: 'toast-samuquinha',
      });

      setCommentsData([response.data, ...commentsData]);

      setComment('');
    } catch (error) {
      console.log(error);
      toast.error('Algo deu errado ao criar sua anotação!', {
        position: 'bottom-center',
        duration: 6000,
      });
    }
  }, [comment, currentLesson, commentsData]);

  const handleDeleteComment = useCallback(
    (id: string) => {
      if (window.confirm('Deseja excluir essa anotação?')) {
        (async () => {
          try {
            if (!currentLesson) {
              return;
            }

            const response = await api.delete(
              `/clusters/${currentLesson.cluster_id}/lessons/${currentLesson.slug}/notes/${id}`,
            );
            console.log(response.data);

            toast.error('Anotação excluída com sucesso!', {
              position: 'bottom-right',
              duration: 6000,
              className: 'toast-samuquinha',
            });

            const newComments = commentsData.filter(value => value.id !== id);
            setCommentsData(newComments);
          } catch (error) {
            console.log(error);
            toast.error('Algo deu errado ao excluir sua anotação!', {
              position: 'bottom-center',
              duration: 6000,
            });
          }
        })();
      }
    },
    [commentsData, currentLesson],
  );

  const handleUserVote = useCallback(
    async (lesson_id: string, voteType: IVoteType) => {
      try {
        const response = await api.post(`/clusters/lessons/${lesson_id}/vote`, {
          type: voteType,
        });

        const { upvotes, downvotes, user_vote } = response.data;

        setLessonsData(oldLessons =>
          oldLessons.map(oldLesson => {
            if (oldLesson.id === lesson_id) {
              return {
                ...oldLesson,
                upvotes: upvotes,
                downvotes: downvotes,
                user_vote: user_vote,
              };
            }

            return oldLesson;
          }),
        );
      } catch (error) {
        console.log(error);
      }
    },
    [],
  );

  useEffect(() => {
    if (lessonSlug) {
      if (lessonSlug === '!') {
        setCurrentLesson(lessonsData[0]);
        handleChangeType(false);
        setIsPlaying(false);
        setShowThumbnail(true);
        return;
      }

      if (lessonsData.length === 0) {
        return;
      }

      const findLesson = lessonsData.find(
        (lesson: ILessonProps) => lesson?.slug === lessonSlug,
      );

      if (findLesson) {
        setCurrentLesson(findLesson);
        handleChangeType(false);
        setIsPlaying(false);
        setShowThumbnail(true);
      }
      else {
        toast.error('Desculpe, esta aula não foi encontrada! 😥', {
          duration: 5000,
          position: 'bottom-right',
          className: 'toast-samuquinha',
        });

        history.push('/modulos');
      }
    }
  }, [lessonsData, lessonSlug, slug, handleChangeType, history]);

  return (
    <>
      <Loader isVisible={loading} />

      <FullWidthContainer>
        <FullWidthMainContent>
          <FullWidthAside>
            <header
              style={
                theme === 'dark'
                  ? { background: '#183a36' }
                  : { background: '#ffffff' }
              }
            >
              <MagnifyingGlass weight="bold" />
              <InputDebounceAdapter
                type="text"
                debounceTimeout={0}
                minLength={0}
                onChange={event => {
                  const mainScroll = document.querySelector('#main-scroll');

                  if (mainScroll) {
                    mainScroll.scrollTo({ top: 0, behavior: 'smooth' });
                  }

                  handleFilterSchedule(event.target.value.toLowerCase());
                }}
                placeholder="Busque por uma aula..."
              />
            </header>
            <main>
              <ModuleITitle>
                {clusterData.type !== 'extra' &&
                  <aside>
                  <img src={clusterData.thumbnail_url} alt={clusterData.title} />
                </aside>}
                <main>
                  <h5>Módulo {clusterData.order}</h5>
                  <h2>{clusterData.title}</h2>
                </main>
              </ModuleITitle>
              <ItemList>
                <ul ref={parentListLessons}>
                  {filteredLessons.length > 0 ? (
                    filteredLessons.map(lesson => (
                      <li key={lesson.id}>
                        <TooltipAdapter alt="Marcar como concluída" place="top">
                          <ViewedLessonButton
                            isViewed={
                              Number(lesson.viewed) >= Number(lesson.duration)
                            }
                            onClick={() => handleLessonCompleted(lesson.id)}
                          />
                        </TooltipAdapter>
                        <Link
                          to={`/aulas/${clusterData.slug}/${lesson.slug}`}
                          onClick={() => {
                            const mainScroll = document.querySelector('#main-scroll');
                            const lessonTitle = document.querySelector('#lesson-title');

                            const headerHeight = 80 + 16;

                            if (mainScroll && lessonTitle && window.innerWidth < 1024) {
                              setTimeout(() => {
                                mainScroll.scrollTo({
                                  top: lessonTitle.getBoundingClientRect().top - headerHeight,
                                  behavior: 'smooth',
                                });
                              }, 1); // 1ms delay to rack the scrollToTop hook on the useEffect Layout
                            }
                          }}
                          className={
                            currentLesson?.id === lesson.id ? 'selected' : ''
                          }
                        >
                          {lesson.title}
                        </Link>
                      </li>
                    ))
                  ) : (
                    <SearchNotFound>
                      Nenhum resultado encontrado. 😢
                    </SearchNotFound>
                  )}
                </ul>
              </ItemList>
            </main>
          </FullWidthAside>

          {!isExercise ? (
            <FullWidthMain>
              <Center>
                <header>
                  <div>
                    {clusterData.id && (
                      <Breadcrumbs
                        icon={House}
                        separator="/"
                        route={[
                          {
                            title: `${clusterType[clusterData?.type].title}`,
                            link: `${clusterType[clusterData?.type].link}`,
                          },
                          {
                            title: `${clusterData.title}`,
                            link: `/aulas/${clusterData.slug}/!`,
                          },
                        ]}
                      />
                    )}
                  </div>
                  <h1 id="lesson-title">
                    Aula{' '}
                    {(currentLesson?.order ?? 0) > 9
                      ? currentLesson?.order
                      : '0' + currentLesson?.order}{' '}
                    - {currentLesson?.title}
                  </h1>
                  <p>{currentLesson?.description}</p>
                </header>

                {showThumbnail ? (
                  <div
                    style={
                      currentLesson?.has_exercises
                        ? { borderRadius: '12px 12px 0 0', width: '100%' }
                        : {
                          borderRadius: '12px',
                          width: '100%',
                          marginBottom: '2rem',
                        }
                    }
                  >
                    <VideoTumbnail
                      videoTitle={currentLesson?.title ?? ''}
                      videoTumbnailUrl={
                        currentLesson?.video_provider === 'panda' ?
                          `https://b-vz-cb1dc143-4cf.tv.pandavideo.com.br/${currentLesson?.video}/thumbnail.jpg`
                          : currentLesson?.video_provider === 'youtube'
                            ? `https://img.youtube.com/vi/${currentLesson?.video}/maxresdefault.jpg`
                            : currentLesson?.thumbnail_url
                      }
                      percentView={
                        (Number(currentLesson?.viewed) * 100) /
                        Number(currentLesson?.duration)
                      }
                      borderRadius={
                        currentLesson?.has_exercises ? '12px 12px 0 0' : '12px'
                      }
                      onClick={onPlay}
                    />
                  </div>
                ) : (
                  <>
                    <PlayerContainer
                      style={
                        currentLesson?.has_exercises
                          ? { borderRadius: '12px 12px 0 0' }
                          : { borderRadius: '12px', marginBottom: '2rem' }
                      }
                    >
                      {(currentLesson?.id && (
                        <PlayerAdapter
                          provider={currentLesson.video_provider}
                          video_url={currentLesson.video_url}
                          video={currentLesson.video}
                          onReady={onReady}
                          onPlay={onPlay}
                          onPause={onPause}
                          onEnded={() => onEnded(currentLesson.id)}
                          onProgress={onProgress}
                        />
                      ))}
                      <LoaderSpinner style={{ zIndex: 0 }} />
                    </PlayerContainer>
                  </>
                )}
                {currentLesson?.has_exercises && (
                  <ExerciseButtonContainer
                    onClick={() => handleChangeType(true)}
                  >
                    <p>
                      <img src="https://samuel-cunha-api.s3.sa-east-1.amazonaws.com/site/fire.png" alt="" />
                      Exercícios de fixaçãos sobre a aula
                      <img src="https://samuel-cunha-api.s3.sa-east-1.amazonaws.com/site/fire.png" alt="" />
                    </p>
                  </ExerciseButtonContainer>
                )}
                <LiteVideo>
                  O vídeo está travando?
                  <Link to={`/video-lite/?title=${currentLesson?.title}&provider=${currentLesson?.video_provider}&videoUrl=${currentLesson?.video_url}`}>
                    <MonitorPlay size={18} color="#ffffff" weight="duotone" /> Experimente o carregamento leve
                  </Link>
                </LiteVideo>
                <RatingVideoContainer>
                  <aside>
                    {currentLesson && (
                      <ToggleLikeAndDeslike
                        upvotes={currentLesson?.upvotes}
                        downvotes={currentLesson?.downvotes}
                        userVote={currentLesson?.user_vote ?? null}
                        onVote={voteType =>
                          handleUserVote(currentLesson?.id, voteType)
                        }
                      />
                    )}
                    <p>
                      <Timer size={20} weight="bold" />{' '}
                      {currentLesson?.duration_formatted}
                    </p>
                    <p>
                      <Eye size={20} weight="bold" /> {currentView}
                    </p>
                  </aside>
                  <main>
                    <TooltipAdapter alt="Marcar como concluída" place="top">
                      {currentLesson && (
                        <ViewedLessonButton
                          isViewed={
                            Number(currentLesson?.viewed) >=
                            Number(currentLesson?.duration)
                          }
                          onClick={() => handleLessonCompleted(currentLesson?.id)}
                          label="Marcar como concluída"
                        />
                      )}
                    </TooltipAdapter>
                  </main>
                </RatingVideoContainer>
              </Center>

              <CommentsContainer>
                <Center>
                  {((currentLesson?.attachments &&
                    currentLesson?.attachments?.length > 0) ||
                    clusterData.material_url) && (
                      <MaterialsContainer>

                        <h3>Materiais de apoio:</h3>

                        <main>
                          {clusterData.material_url && (
                            <CardMaterial
                              href={clusterData.material_url}
                              title={clusterData.title}
                              type="pdf"
                            />
                          )}
                          {currentLesson?.attachments?.map(
                            (attachment, index) => (
                              <CardMaterial
                                href={attachment.file_url}
                                key={index}
                                title={attachment.name}
                                type={attachment.type}
                              />
                            ),
                          )}
                        </main>
                      </MaterialsContainer>
                    )}

                  <header>
                    <h3>Suas anotações sobre a aula ({commentsData.length})</h3>
                  </header>
                  <Commentmain>
                    <aside>
                      <AvatarProgress
                        url={user.avatar_url}
                        percent={user.current_exp}
                        size={80}
                        badgeValue={user.level}
                      />
                    </aside>
                    <main>
                      <div>
                        <textarea
                          placeholder="Adicione uma nova anotação..."
                          onChange={event => setComment(event.target.value)}
                          value={comment}
                        />
                        <button disabled={!comment} onClick={handlePostComment}>
                          Salvar anotação
                        </button>
                      </div>
                    </main>
                  </Commentmain>
                  <MyCommentsContainer
                    ref={parentCommentsList}
                    id="list-comments"
                  >
                    {commentsData.map((comment, index) => (
                      <Fragment key={index}>
                        <header>
                          <h5>
                            {comment?.created_at &&
                              format(
                                parseISO(comment?.created_at),
                                "dd'/'MM'/'Y",
                                { locale: ptBR },
                              )}
                          </h5>
                          <TooltipAdapter
                            alt="Excluir anotação"
                            place="top"
                            cursor="pointer"
                            onClick={() => handleDeleteComment(comment.id)}
                          >
                            <TrashIcon>
                              <Trash weight="duotone" />
                            </TrashIcon>
                          </TooltipAdapter>
                        </header>
                        <p>{comment?.content}</p>
                        <Hr />
                      </Fragment>
                    ))}
                  </MyCommentsContainer>
                </Center>
              </CommentsContainer>
            </FullWidthMain>
          ) : (
            <FullWidthMain>
              <Center>
                <BreadcrumbsContainer onClick={() => setIsExercise(false)}>
                  <CaretLeft weight="bold" size={16} />
                  <button>
                    Voltar para a aula
                  </button>
                </BreadcrumbsContainer>
                {currentLesson && (
                  <ExerciseModule lessson_id={currentLesson.id} />
                )}
              </Center>
            </FullWidthMain>
          )}
        </FullWidthMainContent>
      </FullWidthContainer>
    </>
  );
};

export default AulasModulo;