import styled from 'styled-components';

const fixedHeaderHeight = '0px';

export const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  justify-content: space-between;
`;

export const FullWidthContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "Header Header Header"
    "MainContent MainContent MainContent"
    "MainContent MainContent MainContent";
`;

export const FullWidthMainContent = styled.section`
  display: grid;
  grid-template-columns: minmax(350px, 0.3fr) 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "Aside Main Main"
    "Aside Main Main"
    "Aside Main Main";
  grid-area: MainContent;

  @media (max-width: 1200px) {
    grid-template-columns: minmax(285px, 0.3fr) 1fr;
  }
  
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;

export const FullWidthAside = styled.aside`
  position: relative;
  grid-area: Aside;
  background: ${props => props.theme.specific.asideModule};
  border-right: 1px solid ${props => props.theme.header.border};
  box-shadow: rgb(0 0 0 / 4%) 0px 0px 90px 0px;
  z-index: 9;
  height: 100%;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 19px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${props => props.theme.colors.muted}50;
  }
  
  > main {
    padding: 0 2rem 1rem 2rem;
    margin-top: 1rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 1024px) {
      height: auto;
      max-height: 335px;
      overflow-y: scroll;

      ::-webkit-scrollbar {
        width: 20px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 8px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: ${props => props.theme.colors.muted}50;
      }
    }
  }
`;

export const FullWidthMain = styled.main`
  grid-area: Main;

  > div {

    > div {

      > div {

        @media (max-width: 768px) {
          min-height: 370px !important;
        }

        @media (max-width: 425px) {
          min-height: 150px !important;
        }
      }
    }
  }
`;

export const PlayerContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 12px 12px 0 0;
  overflow: hidden;

  &.react-player > div {
    position: absolute !important; // Scaling will occur since parent is relative now
  }

  .react-player {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    background: ${props => props.theme.cards.background} !important;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export const ExerciseButtonContainer = styled.footer`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0 0 12px 12px;
  padding: 1rem;
  margin-bottom: 2rem;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.muted}30;
  animation: 2s infinite ease-in-out pulseAnimation2;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  cursor: pointer;

  @media (max-width: 425px) {
    font-size: 13px;
  }

  @media (max-width: 400px) {
    font-size: 11px;
  }

  &:hover {
    background: ${props => props.theme.colors.muted}60;
  }

  > p {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    > img {
      height: 20px;
      width: 20px;
    }
  }
`

export const ModuleITitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  //margin-bottom: 1rem;
  border-bottom: 1px solid ${props => props.theme.cards.border};
  padding-bottom: 1rem;

  > aside {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.background.primary};
    height: 40px;
    width: 40px;
    border-radius: 50%;

    > svg {

    }

    > img {
      height: 40px;
      width: fit-content;
    }
  }

  > main {

    > h5 {
      font-size: 12px;
      font-weight: 500;
      color: ${props => props.theme.colors.muted};
    }

    > h2 {
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
    }
  }
`;

export const ItemList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  user-select: none;
  
  > ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 1rem 0;
    
    > li {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding-left: 1rem;
      z-index: 0;

      > p, a {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        font-size: 13px;
        cursor: pointer;
        color: ${props => props.theme.colors.white};
        text-decoration: none;
        transition: padding 0.2s ease-in-out 0s,color 0.2s ease-in-out 0s;

        &.selected {
          font-weight: 900 !important;
        }
        
        &:hover {
          padding-left: 4px;
        }
        
        &.selected {
          font-weight: 600;
        }

        > span {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
`;

export const RatingVideoContainer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 425px) {
    justify-content: center;
  }

  > aside {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;

    > span {
      font-size: 14px;
    }

    > p {
      display: flex;
      align-items: center;
      font-size: 12px;
      gap: 0.5rem;
      padding-left: 1rem;
      color: ${props => props.theme.colors.muted};
      border-left: 1.75px solid ${props => props.theme.colors.muted}70;

      > svg {
        font-size: 20px;
      }
    }
  }

  > div {

    @media (max-width: 580px) {
      display: none;
    }

    > main {
      border: 2px solid ${props => props.theme.colors.gray};
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0 16px;
      height: 45px;
      border-radius: 30px;
      cursor: pointer;
  
      > span {
        font-size: 14px;
      }
    }
  }
`;

export const Center = styled.div`
  width: 100%;
  padding: 4rem 25rem;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  > div {
    > div {
      min-height: 500px;
    }
  }

  @media (max-width: 2300px) {
    padding: 4rem 22rem;
  }

  @media (max-width: 2100px) {
    padding: 4rem 18rem;
  }

  @media (max-width: 1900px) {
    padding: 4rem 15rem;
  }

  @media (max-width: 1600px) {
    padding: 4rem 12rem;
  }

  @media (max-width: 1500px) {
    padding: 4rem 8rem;
  }

  @media (max-width: 1440px) {
    padding: 4rem 2rem;
  }
  
  > header {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;
    
    > div {
      margin-bottom: 3rem;
    }
    
    > h1 {
      margin-bottom: 1rem;
      font-size: 40px;
    }
    
    > p {
      font-size: 14px;
    }
  }
`;

export const CommentsContainer = styled.section`
position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.specific.footerModule};
  border-top: 1px solid ${props => props.theme.header.border};

  &:before {
    content: '';
    position: absolute;
    left: 9rem;
    top: -14px;
    width: 0px;
    height: 0px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid ${props => props.theme.specific.footerModule};
  }

  > div {

    > header{
      display: flex;

      > h3 {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
`;


export const Commentmain = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  > aside {

    @media (max-width: 500px) {
      display: none;
    }
  }

  > main {
    width: 100%;
    margin-bottom: 2rem;
    position: relative;

    > div {
      width: 100%;
      border: 1px solid #aaa; 
      padding: 1rem; 
      border-top: 1px solid ${props => props.theme.colors.white}70;
      border-left: 1px solid ${props => props.theme.colors.white}70;
      border-right: 1px solid ${props => props.theme.colors.white}70;
      border-bottom: 1px solid ${props => props.theme.colors.white}70;
      border-top-left-radius: 0px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      > textarea {
        width: 100%;
        min-height: 120px;
        border: none;
        padding: 0px;
        padding-bottom: 2rem;
        box-sizing: border-box;
        background: transparent;
        resize: none;
        color: ${props => props.theme.colors.white};
      }

      > button {
        position: absolute;
        left: 0;
        bottom: 0;
        background: transparent;
        width: 100%;
        height: 2rem;
        margin-top: -2rem;
        border: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        color: ${props => props.theme.colors.white};
        text-align: right;
        padding: 0 12px;
        cursor: pointer;
        font-weight: 600;

        &:disabled {
          color: ${props => props.theme.colors.white}70;
          cursor: auto;
        }
      }
    }
  }
`;


export const MyCommentsContainer = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 1rem;
  }

  > p {
    text-align: justify;
    font-size: 14px;
  }

  > hr {
    margin: 2rem 0;
  }
`;

export const TrashIcon = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #948cbf1c;
  border-radius: 50%;

  > svg {
    color: ${props => props.theme.colors.white};
  }
`;

export const StudyPlanName = styled.div`
  position: relative;
  position: sticky;
  top: 0px;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 1;
  background: ${props => props.theme.specific.asideModule};
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  height: 56px;
  border-bottom: 1px solid ${props => props.theme.cards.border};

  @media (max-width: 768px) {
    height: auto;
    padding: 1rem;
    border-left: none;
    border-top: 1px solid ${props => props.theme.cards.border};
  }

  > p { 
    font-size: 13px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 0.25rem;
    font-weight: bold;
    text-transform: uppercase;
    color: ${props => props.theme.colors.white};

    @media (max-width: 768px) {
      font-size: 11px;
    }

    > svg {
      height: 24px;
      width: 24px;
      margin-bottom: 0.25rem;
    }
    
    > a {
      color: ${props => props.theme.colors.white};
      text-transform: uppercase;
    }
  }
`;

export const MaterialsContainer = styled.section`
  position: relative;
  width: 100%;
  margin: 0 0 2rem 0;

  > h3 {
    font-size: 18px;
    font-weight: 700;
  }

  > main {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    align-items: start;

    @media (max-width: 425px) {
      display: flex;
      flex-direction: column;

      > div {
        width: 100%;

        > a {
          width: 100%;
        }
      }
    }
  }
`;

export const AccordionDetailsList = styled.details`
  position: relative;
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column !important;
  
  > summary {
    position: relative;
    width: 100%;
    cursor: pointer;
    user-select: none;
    color: ${props => props.theme.colors.white};

    &::marker {
      display: none;
      content: '';
    }

    > div {

      > div {

        > aside {

          > svg {
            color: ${props => props.theme.colors.white};
          }
        }

        > main {

          > h2 {
            color: ${props => props.theme.colors.white};
          }
        }
      }
    }
  }

  &::after {
    content: '►';
    color: ${props => props.theme.colors.muted}50;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    z-index: -1;
    transition: all .2s ease-in-out;

  }
  &[open]::after {
    content: '▼';
  }
`;

export const BreadcrumbsContainer = styled.div`
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  
  > button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: ${props => props.theme.colors.white};
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
      transform: translateX(0.25rem);
    }
  }
`;


export const LoaderLessonsContainer = styled.section`
  position: relative;
  width: 100%;
  scale: 0.6;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const LiteVideo = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  color: ${props => props.theme.colors.white};
  font-weight: 600;

  @media (max-width: 425px) {
    flex-direction: column;
  }

  > a {
    text-decoration: none;
    border-radius: 20px;
    background: #6c23c0;
    color: #ffffff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    font-size: 14px;
    cursor: pointer;
    text-wrap: nowrap;

    @media (max-width: 425px) {
      font-size: 12px;
    }

    > svg {
      font-size: 20px;
      width: fit-content;
    }
  }
`;